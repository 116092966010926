<template>
  <div class="main-content">
    <div class="layout-px-spacing mb-5">
      <div class="row layout-top-spacing">
        <div
          class="col-12 col-md-12 col-lg-8 offset-lg-2 layout-spacing bg-white p-4 shadow"
        >
          <div class="p-2 text-center" v-if="loadingReport">
            <b-card class="">
              <b-skeleton animation="wave" width="85%"></b-skeleton>
              <b-skeleton animation="wave" width="67%"></b-skeleton>
              <b-skeleton animation="wave" width="70%"></b-skeleton>
              <b-skeleton animation="wave" width="82%"></b-skeleton>
              <b-skeleton animation="wave" width="65%"></b-skeleton>
              <b-skeleton animation="wave" width="90%"></b-skeleton>
              <b-skeleton animation="wave" width="95%"></b-skeleton>
              <b-skeleton type="button"></b-skeleton>
            </b-card>
          </div>

          <form v-else>
            <div class="row mb-4">
              <div class="col-12 col-md-6 mb-3">
                <div class="form-group">
                  <label for="Name">Name</label>
                  <input
                    type="text"
                    :value="`${report.user.firstname} ${report.user.lastname}`"
                    id="Name"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>

              <div class="col-12 col-md-6 mb-3">
                <div class="form-group">
                  <label for="dept">Department</label>
                  <input
                    type="text"
                    :value="`${report.user.role.toUpperCase()}`"
                    id="dept"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>

              <div class="col-12 mb-5">
                <div>
                  <p class="font-weight">Routine Tasks</p>
                  <p style="color: #acb0c3">
                    Routine tasks you carry out every week according to your job
                    description. Minimum of 3 and Max of 5. For tech these are
                    tasks you have been instructed to carry out by Management. -
                    50 Points <span class="text-danger">*</span>
                  </p>
                </div>

                <div>
                  <b-input-group
                    v-for="(input, index) in routineTasks"
                    :key="`routineTask-${index}`"
                    class="input wrapper flex items-center mb-1"
                  >
                    <b-form-textarea
                      readonly
                      type="text"
                      v-model="routineTasks[index]"
                      :disabled="
                        routineTasks.length < routineCount ||
                        index < routineCount
                      "
                    ></b-form-textarea>
                  </b-input-group>
                </div>
              </div>

              <div class="col-12 mb-5" v-if="outstandingTasks.length > 0">
                <div>
                  <p class="font-weight">Outstanding Tasks</p>
                  <p style="color: #acb0c3">
                    Outstanding Tasks from the previous week - Completion of
                    outstanding tasks from the previous week - 20 Points
                    <span class="text-danger">*</span>
                  </p>
                </div>

                <div>
                  <b-input-group
                    v-for="(input, index) in outstandingTasks"
                    :key="`outstandingTask-${index}`"
                    class="input wrapper flex items-center mb-1"
                  >
                    <b-form-textarea
                      readonly
                      type="text"
                      v-model="outstandingTasks[index]"
                    ></b-form-textarea>
                  </b-input-group>
                </div>
              </div>

              <div class="col-12 mb-5">
                <div>
                  <p class="font-weight">New Tasks</p>
                  <p style="color: #acb0c3">
                    Tasks given to you during the week that may or may not be
                    directly related to your job description - 20 Points
                    <span class="text-danger">*</span>
                  </p>
                </div>

                <button
                  class="btn btn-outline-success btn-xs"
                  @click.prevent="addField(newTasks)"
                >
                  <i class="fa fa-plus"></i>
                </button>

                <div>
                  <b-input-group
                    v-for="(input, index) in newTasks"
                    :key="`newTask-${index}`"
                    class="input wrapper flex items-center mb-1"
                  >
                    <b-form-textarea
                      type="text"
                      v-model="newTasks[index]"
                    ></b-form-textarea>

                    <b-input-group-append>
                      <b-button
                        variant="danger"
                        v-show="newTasks.length > 1"
                        @click="removeField(index, newTasks)"
                        ><i class="fa fa-times"></i
                      ></b-button>
                    </b-input-group-append>
                  </b-input-group>
                </div>
              </div>

              <div class="col-12 mb-5">
                <div>
                  <p class="font-weight">Any Other Tasks</p>
                  <p style="color: #acb0c3">
                    Pro active tasks you give yourself during the week that move
                    GeroCare forward. - 10 Points
                    <span class="text-danger">*</span>
                  </p>
                </div>

                <button
                  class="btn btn-outline-success btn-xs"
                  @click.prevent="addField(otherTasks)"
                >
                  <i class="fa fa-plus"></i>
                </button>

                <div>
                  <b-input-group
                    v-for="(input, index) in otherTasks"
                    :key="`otherTask-${index}`"
                    class="input wrapper flex items-center mb-1"
                  >
                    <b-form-textarea
                      type="text"
                      v-model="otherTasks[index]"
                    ></b-form-textarea>

                    <b-input-group-append>
                      <b-button
                        variant="danger"
                        v-show="otherTasks.length > 1"
                        @click="removeField(index, otherTasks)"
                        ><i class="fa fa-times"></i
                      ></b-button>
                    </b-input-group-append>
                  </b-input-group>
                </div>
              </div>

              <div class="col-12 text-center">
                <button
                  type="button"
                  @click.prevent="updateTask()"
                  class="btn btn-success"
                  :disabled="loading"
                >
                  <b-spinner small v-if="loading"></b-spinner>
                  <span v-else
                    >Save Changes<i class="fa fa-paper-plane"></i>
                  </span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { endpoints } from "@/utils/endpoints";
import { http } from "@/utils/http";

import { mapGetters } from "vuex";

export default {
  name: "EditTask",

  computed: {
    ...mapGetters(["user"]),
  },

  data() {
    return {
      loadingReport: true,
      loading: false,

      routineTasks: [],
      routineCount: 0,

      newTasks: [],

      otherTasks: [],

      outstandingTasks: [],

      date: null,
      prevScore: null,

      id: null,

      report: [],
    };
  },

  mounted() {
    this.id = this.$route.params.id;
    this.fetchReport();
  },

  methods: {
    addField(fieldType) {
      fieldType.push("");
    },

    removeField(index, fieldType) {
      fieldType.splice(index, 1);
    },

    fetchReport() {
      this.loadingReport = true;
      http
        .get(endpoints.SINGLE_TASK.replace(":id", this.id))
        .then((response) => {
          this.report = response;

          this.report.items.map((x) => {
            if (x.type == "routine") {
              this.routineTasks.push(x.description);
            }
          });

          this.report.items.map((x) => {
            if (x.type == "new") {
              this.newTasks.push(x.description);
            }
          });

          this.report.items.map((x) => {
            if (x.type == "other") {
              this.otherTasks.push(x.description);
            }
          });

          this.report.items.map((x) => {
            if (x.type == "outstanding") {
              this.outstandingTasks.push(x.description);
            }
          });

          this.loadingReport = false;
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },

    updateTask() {
      this.loading = true;
      http
        .put(endpoints.UPDATE_TASK, {
          id: this.id ?? this.report.id,
          newTasks: this.newTasks,
          otherTasks: this.otherTasks,
        })
        .then((response) => {
          this.loading = false;
          this.$toast.success(response);
          this.$router.go(-1);
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },
  },
};
</script>

<style scoped src="@/assets/css/forms/theme-checkbox-radio.css"></style>
<style scoped src="@/assets/css/forms/switches.css"></style>
<style>
.fab-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  user-select: none;
  position: sticky;
  bottom: 30px;
  right: 30px;
}

.fab-container .fab {
  position: relative;
  height: 90px;
  width: 90px;
  background-color: #28a745;
  border-radius: 50%;
  z-index: 9999;
}
.fab-container .fab::before {
  content: " ";
  position: absolute;
  bottom: 0;
  right: 0;
  height: 35px;
  width: 35px;
  background-color: inherit;
  border-radius: 0 0 10px 0;
  z-index: -1;
}
.fab-container .fab .fab-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: white;
  font-weight: bolder;
  font-size: 20px;
  width: 100%;
  border-radius: 50%;
}
</style>
